@import 'styles/mixins';
@import 'styles/variables';

.homeContainer {
  min-height: 100%;
  background-color: $white;
  background-color: $bg-grey;

  .mask {
    width: 100%;
    height: 440px;
    overflow: hidden;
    margin-top: 50px;
    z-index: -1;

    @include small-desktop-and-up {
      height: 650px;
    }

    .background {
      position: relative;
      height: 100%;
      width: auto;
      background-image: linear-gradient(to bottom, #5b00db 11%, #12dfc7 89%);
      padding: 40px 20px;
      text-align: center;

      @include small-desktop-and-up {
        background-image: linear-gradient(to bottom, #5b00db 14%, #12dfc7);
        padding: 40px 0 0 0;
      }

      @include small-desktop-and-up {
        padding: 70px 0 0 0;
      }
    }

    .angle {
      position: absolute;
      width: 100%;
      height: 35%;
      clip-path: polygon(0 40%, 0% 100%, 100% 100%);
      bottom: 0;
      left: 0;
      background: $white;
      background-color: $bg-grey;
    }

    .landingAngle {
      background-color: $bg-grey;
    }
  }

  .content {
    position: relative;
    margin-top: -200px;

    &.landingContent {
      background-image: linear-gradient(to bottom, #f2f4f9, #fff 99%);
    }

    @include small-desktop-and-up {
      margin-top: 80px;

      &.landingContent {
        margin-top: 0;
      }
    }

    h4 {
      font-size: 14px;
      color: $main;
      opacity: 0.8;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      font-family: $avenir-black;
      text-align: center;
      padding-top: 20px;

      @include small-desktop-and-up {
        padding-bottom: 60px;
        padding-top: 0;
        font-size: 20px;
      }
    }

    .text {
      font-size: 18px;
      padding: 40px 20px;
      text-align: center;
      background-color: rgb(242, 244, 249);
    }
  }

  .bottomSection {
    text-align: center;
    padding: 40px 15px 20px;
    background-color: $white;

    @include small-desktop-and-up {
      padding-top: 80px;
    }

    h3 {
      font-size: 18px;
      padding: 0 20px 20px;
    }
  }

  .whiteBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 28px;
    background-color: rgb(242, 244, 249);
    margin-top: -27px;
  }

  .shadow {
    position: absolute;
    top: 0;
    background-image: linear-gradient(rgba(242, 244, 249, 0), rgb(242, 244, 249) 95%);
    width: 100%;
    height: 80px;
    margin-top: -106px;
  }

  .coverImage {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: -130px auto 30px auto;
    max-width: 1140px;

    @media screen and (min-width: 355px) {
      margin-top: -170px;

      &.landingCover {
        margin-top: -227px;
      }
    }

    @media screen and (min-width: 600px) {
      margin-top: -400px;

      &.landingCover {
        margin-top: -400px;
      }
    }

    @include small-desktop-and-up {
      margin-top: -580px;
      flex-direction: row;
      padding: 0 15px;

      &.landingCover {
        margin-top: -580px;
      }
    }

    @include desktop {
      margin-top: -550px;

      &.landingCover {
        margin-top: -550px;
      }
    }

    @media screen and (min-width: 1160px) {
      padding: 0;
    }

    .imgContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      order: 2;

      @include small-desktop-and-up {
        justify-content: inherit;
        margin-top: 0;
        order: 1;
      }

      img:first-child {
        width: 180px;
        margin-right: -130px;

        @media screen and (min-width: 390px) {
          width: 230px;
        }
      }

      img:nth-child(2) {
        width: 100%;
        width: 280px;
      }
    }

    .rightSectionContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include small-desktop-and-up {
        order: 2;
      }

      button,
      a {
        width: 100%;
        width: 230px;
      }

      .topButtons {
        display: flex;
        gap: 10px;
        justify-content: center;

        button,
        a {
          width: 50%;

          @include small-desktop-and-up {
            width: 100%;
            width: 230px;
          }
        }
      }
    }
  }

  .homeSection {
    background-image: linear-gradient(to bottom, #f2f4f9, #fff 99%);
    margin-top: -1px;

    @include small-desktop-and-up {
      padding-bottom: 40px;
      margin-top: 0;
    }

    .section {
      width: 90%;
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include small-desktop-and-up {
        width: 100%;
      }

      ul {
        display: flex;
        list-style-type: none;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;

        @media screen and (min-width: 1400px) {
          width: 100%;
          justify-content: space-evenly;
        }

        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 300px;
          margin-right: 10px;
          text-align: center;
          margin-bottom: 20px;

          @include small-desktop-and-up {
            width: 275px;
          }

          svg {
            width: 50px;
            height: 50px;

            @include small-desktop-and-up {
              width: 80px;
              height: 80px;
            }
          }

          h3 {
            font-size: 18px;
            margin-top: 15px;

            @include small-desktop-and-up {
              font-size: 24px;
            }
          }
        }
      }

      p {
        margin: 20px 10px;
        font-size: 16px;
        max-width: 946px;

        @include small-desktop-and-up {
          font-size: 18px;
        }
      }
    }
  }

  .aboutUs {
    background-color: rgba(18, 223, 199, 0.1);
    padding: 20px 5% 40px;
    margin-top: 10px;
    text-align: center;
    justify-content: center;

    @include small-desktop-and-up {
      padding: 0 10% 100px;
      margin-top: 10px;
    }

    h1 {
      font-size: 30px;
      line-height: 1.4;

      @include small-desktop-and-up {
        font-size: 50px;
      }
    }

    p {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      font-size: 16px;
      margin: 20px auto 0;
      max-width: 900px;

      @include small-desktop-and-up {
        font-size: 18px;
      }
    }
  }

  .rightSection {
    h2,
    h3 {
      color: $white;
    }

    h2 {
      font-size: 24px;
      max-width: 600px;

      @include small-desktop-and-up {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 14px;
      font-family: $avenir-primary;
      font-weight: 400;
      color: $bg-grey;
      margin-bottom: 45px !important;

      @media screen and (min-width: 390px) {
        font-size: 16px;
      }

      @include small-desktop-and-up {
        font-size: 24px;
      }
    }
  }
} // end of homeContainer
